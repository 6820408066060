








































































































































































































































































































































































import Vue from "vue";

import { setRoute } from "../services/route";
import { logEventFA } from "../utils/utils";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  data() {
    return {
      setRoute,
      logEventFA,
    };
  },
  computed: {
    ...mapGetters("root", ["isSettingBtnActive"]),
    ...mapGetters("user", ["userId"])
  },
  methods: {
    ...mapMutations("root", ["updateHelpData"]),
    setOptionalBtn(isActive: boolean) {
      this.updateHelpData({isSettingBtnActive: isActive});
    },
  },
});
