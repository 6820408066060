





















































import Vue from "vue";

import { appState } from "../store/appstate";
import { mapActions, mapGetters } from "vuex";

let curOffsetY = 0;
let prevTouchY = 0;
let curTouchY = 0;
let _startY: any;
let appEl: any;
// eslint-disable-next-line
let refreshEl: any;
let isScrolling = false;
const enabledRefresherViews = ["Root"];

export default Vue.extend({
  // props: {
  //     tabsContentEls: Array as Prop<any>
  // },
  data() {
    return {
      isRefreshing: false,
      showRefresh: false,
      refreshValue: 0, // 0-100
    };
  },

  mounted() {
    // PulltoRefresh
    appEl = document.getElementById("appContainer");
    // refreshEl = document.getElementsByClassName('refresh')[0]
    refreshEl = this.$refs.refreshEl;

    appEl.addEventListener("touchstart", this.onTouchStart, { passive: true });
    appEl.addEventListener("touchmove", this.onTouchMove, { passive: true });
    // Reset refresher. Cancel refresh data
    appEl.addEventListener("touchend", this.onTouchEnd, { passive: true });
  },

  destroyed() {
    appEl.removeEventListener("touchstart", this.onTouchStart);
    appEl.removeEventListener("touchmove", this.onTouchMove);
    // Reset refresher. Cancel refresh data
    appEl.removeEventListener("touchend", this.onTouchEnd);
  },

  computed: {
    ...mapGetters("root", ["isPopupActive", "isPopupSlideActive"]),
    refreshValueLoading(): number {
      // 0-100
      // return 100 while loading
      if (this.isRefreshing) return 100;
      else return this.refreshValue;
    },
    isEnable(): boolean {
      return enabledRefresherViews.includes(this.$route.name || '');
    },
  },

  methods: {
    ...mapActions("root", ["updateBaseData"]),
    onTouchStart(e: any) {
      // if (appState.currentView !== 'home' || appState.isPopupActive || appState.isPopupSlideActive) {
      if (this.isPopupActive || this.isPopupSlideActive || !this.isEnable) {
        isScrolling = false;
        return false;
      }
      // if (this.tabsContentEls) { // tabs mode
      //     // activeTabIdxLocal = this.appstate.activeTabIdx
      //     // tabsLen = tabsContentEls.length
      //     scrollingEl = this.tabsContentEls[ appState.activeTabIdx ]
      // } else { // single page (without tabs)
      // scrollingEl = document.scrollingElement
      // }
      // only for appDataLoaded (user auth and load app data)
      if (!this.isRefreshing && window.scrollY <= 0) {
        // _startY = e.touches[0].pageY
        _startY = e.touches[0].clientY;
        isScrolling = true;
        prevTouchY = _startY;
        this.refreshValue = 0;
      } else isScrolling = false;
    },

    onTouchMove(e: any) {
      if (!isScrolling) return false;

      if (this.isRefreshing) return false;
      // curTouchY = e.touches[0].pageY
      curTouchY = e.touches[0].clientY;
      // Activate custom pull-to-refresh effects when at the top of the container
      // and user is scrolling up.
      curOffsetY = curTouchY - _startY;
      if (window.scrollY === 0 && curOffsetY > 0) {
        // if (scrollingEl.scrollTop === 0 && curTouchY > _startY) {
        this.showRefresh = true;
      }
      if (this.showRefresh) {
        if (curTouchY < prevTouchY) {
          // toTop swipe direction
          if (this.refreshValue > 0) {
            this.refreshValue = this.refreshValue - (prevTouchY - curTouchY); // prev value - offset
            // window.scroll(0,0)
          }
        } else {
          // toBottom swipe direction
          if (this.refreshValue < 99) {
            // small swipe. update arc
            this.refreshValue = this.refreshValue + (curTouchY - prevTouchY); // prev value + offset
          }
        }
      }

      prevTouchY = curTouchY;
    },

    async onTouchEnd() {
      // if (appState.currentView !== 'home' || appState.isPopupActive || appState.isSidebarMobileOpen)
      if (this.isPopupActive || appState.isSidebarMobileOpen) return false;

      if (this.refreshValue >= 99 && !this.isRefreshing) {
        // big swipe. refresh app data
        // check for only one refresh data
        this.isRefreshing = true;
        await this.updateBaseData()
        this.refreshValue = 0;
        this.isRefreshing = false;
        this.showRefresh = false;
      } else this.refreshValue = 0;

      isScrolling = false;
      // this.showRefresh = false
      // this.strokeDasharray = '0, 100'
    },
  },
});
