






















































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";

import { setRoute } from "../services/route";
import eventsRegister from "@/services/events-register";
import { leftArrowSVG, svgIconTelegram, svgIconViber } from "@/services//svg";
import { logEventFA, openExternal } from "../utils/utils";
import { mapGetters } from "vuex";
const telegramBot = process.env.VUE_APP_TELEGRAMBOT; // https://t.me/U_dev_notification_Bot?start=

export default Vue.extend({
  data() {
    return {
      leftArrowSVG,
      svgIconTelegram,
      svgIconViber,
      setRoute,
      logEventFA,

      isInfoOptionsActive: false,
    };
  },

  computed: {
    ...mapGetters("user", ["userId", "user", "logList"]),
    newMsgCounter(): number {
      const lastViewDate = new Date(this.user.pr568);
      let msgDate: any;

      let counter = 0;
      this.logList.forEach((item: any) => {
        if (item.F === 6) {
          // shopping chat message
          if (!item.DT_View_UTC) counter++;
        } else {
          // other log messages
          msgDate = new Date(item.DT_UTC);
          if (msgDate > lastViewDate) counter++;
        }
      });
      return counter;
    },
  },

  methods: {
    subscribeOpenTelegram() {
      openExternal(telegramBot + "?start=" + this.user.pr236);

      logEventFA("select_content", {
        content_type: "telegram",
        customer_id: this.userId,
      });

      this.isInfoOptionsActive = false;
    },

    openWhatsapp() {
      const whatsAppNumber = 13055048599;
      openExternal(`https://wa.me/${whatsAppNumber}`);
    },
    subscribeOpenViber() {
      const viberBot = process.env.VUE_APP_VIBERBOT;
      const viberURI =
        viberBot + "&context=" + this.user.pr236 + "&text=/start";
      if (!window["device"]) {
        openExternal(viberURI);
        return;
      }

      window["plugins"].launcher.launch(
        {
          uri: viberBot + "&context=" + this.user.pr236 + "&text=/start",
        },
        (res: any) => console.log("success viber", res),
        (err: any) => {
          console.log("viber", err);
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("errorOpenViber"),
          });
        }
      );

      logEventFA("select_content", {
        content_type: "viber",
        customer_id: this.userId,
      });
      this.isInfoOptionsActive = false;
    },
  },
});
