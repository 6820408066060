<template>
  <div>
    <refresher
      v-if="appState.isOnline && !appState.isNetworkError"
      :appstate="appState"
    />
    <header-main />
    <slot />
    <panel-bottom />
  </div>
</template>

<script>
import PanelBottom from "@/components/PanelBottom.vue";
import Refresher from "@/components/Refresher.vue";
import HeaderMain from "@/components/HeaderMain.vue";
import appData from '@/store/appdata';
import appState from '@/store/appstate';

export default {
  name: "RootLayout",
  components: {
    PanelBottom,
    Refresher,
    HeaderMain,
  },
  data() {
    return {
      appdata: appData,
      appState: appState,
    };
  },
};
</script>

<style scoped>
</style>